<template>
  <div class="msg_chart">
    <slot-header :showIcon="false" @onBack="handleBack">
      <div>{{ $route.params.name }}</div>
      <span class="msg_chart_status">{{ onlineStatus }}</span>
    </slot-header>
    <div class="msg_chart_body tw-w-full tw-overflow-x-hidden" ref="list" @click="closeOther">
      <div>
        <div v-for="(item, index) in msgContent" :key="index" class="msg_chart_item">
          <template v-if="item.type === 'TIMCustomElem'">
            <div v-if="item.payload.extension" class="msg_chart_item_custom">
              {{ item.payload.extension }}
            </div>
            <template v-else>
              <div class="msg_chart_item_order" :class="JSON.parse(item.payload.data).userGenerationMethodDesc == '提现' ? 'bule' : ''" @click="goToDetail(item)">
                <div class="msg_chart_item_order_amount">
                  <div>
                    {{ JSON.parse(item.payload.data).userGenerationMethodDesc }}
                  </div>
                  <div>
                    <span class="msg_chart_item_order_amount_money">{{ JSON.parse(item.payload.data).orderAmount }}</span>
                    <span class="msg_chart_item_order_amount_currency">元</span>
                  </div>
                </div>
                <div class="msg_chart_item_order_info">
                  <div>下单时间：</div>
                  <div class="msg_chart_item_order_info_date">
                    {{ JSON.parse(item.payload.data).createdAt }}
                  </div>
                </div>
                <div class="msg_chart_item_order_icon">
                  <img src="@/assets/images/public/ic_direction.png" />
                </div>
              </div>
            </template>
          </template>
          <template v-else-if="item.type === 'TIMTextElem'">
            <div class="msg_chart_item_text" :class="{ fromMe: isFromMe(item.from) }">
              <img class="msg_chart_item_text_avatar" :src="getAvatar(item.avatar)" />
              <div class="msg_chart_item_text_triangle"></div>
              <div class="msg_chart_item_text_content tw-max-w-[66%]">
                <template v-for="(msgItem, msgIndex) in decodeText(item.payload)">
                  <p class="msg_chart_item_text_content_text tw-break-words" :key="msgIndex" v-if="msgItem.name === 'text'">{{ msgItem.text }}</p>
                  <img class="msg_chart_item_text_content_emoji" :key="msgIndex" v-if="msgItem.name === 'img'" :src="msgItem.src" />
                </template>
              </div>
              <div v-if="isFromMe(item.from) && item.isRead" class="msg_chart_item_text_status">
                <div>{{ !item.isPeerRead ? "未读" : "已读" }}</div>
              </div>
            </div>
          </template>
          <template v-else-if="item.type === 'TIMImageElem'">
            <div class="msg_chart_item_text" :class="{ fromMe: isFromMe(item.from) }">
              <img class="msg_chart_item_text_avatar" :src="getAvatar(item.avatar)" />
              <div class="tw-relative tw-overflow-hidden">
                <img class="tw-w-full tw-max-w-[250px] tw-h-full tw-max-h-96 tw-object-contain" :src="item.payload.imageInfoArray[0].imageUrl" @click="reviewImg(item.payload.imageInfoArray)" />
              </div>
              <div v-if="isFromMe(item.from) && !item.isRead" class="msg_chart_item_text_status">
                {{ !item.isPeerRead ? "未读" : "已读" }}
              </div>
            </div>
          </template>
          <template v-else-if="item.type === 'TIMVideoFileElem'">
            <div class="msg_chart_item_text" :class="{ fromMe: isFromMe(item.from) }">
              <img class="msg_chart_item_text_avatar" :src="getAvatar(item.avatar)" />
              <div class="tw-relative tw-overflow-hidden">
                <video controls playsinline muted class="tw-w-full tw-max-w-[250px] tw-h-full tw-max-h-96 tw-object-contain">
                  <source :src="item.payload.remoteVideoUrl + '#t=0.001'" :type="`video/mp4`" />
                </video>
              </div>
              <div v-if="isFromMe(item.from) && !item.isRead" class="msg_chart_item_text_status">
                未读
              </div>
            </div>
          </template>
          <template v-else-if="item.type === 'TIMSoundElem'">
            <div class="msg_chart_item_text" :class="{ fromMe: isFromMe(item.from) }">
              <img class="msg_chart_item_text_avatar" :src="getAvatar(item.avatar)" />
              <div class="tw-relative tw-overflow-hidden">
                <audio controls>
                  <source :src="item.payload.remoteAudioUrl" type="audio/ogg" />
                  <source :src="item.payload.remoteAudioUrl" type="audio/mpeg" />
                  <source :src="item.payload.remoteAudioUrl" type="audio/m4a" />
                  Your browser does not support the audio element.
                </audio>
              </div>
              <div v-if="isFromMe(item.from) && !item.isRead" class="msg_chart_item_text_status">
                未读
              </div>
            </div>
          </template>
          <template v-else>
            <div class="msg_chart_item_text" :class="{ fromMe: isFromMe(item.from) }">
              <img class="msg_chart_item_text_avatar" :src="getAvatar(item.avatar)" />
              <div class="tw-relative tw-overflow-hidden">
                <a :href="item.payload.fileUrl" class=" tw-no-underline" target="_blank">
                  <div class="tw-w-full  tw-max-w-[175px]  tw-bg-white tw-rounded-md tw-flex tw-gap-1.5 tw-items-center tw-justify-center tw-px-2 tw-py-2">
                    <div class="tw-w-full tw-overflow-hidden">
                      <p class="tw-text-left tw-text-black tw-whitespace-nowrap tw-text-sm tw-font-semibold  tw-truncate">
                        {{ item.payload.fileName }}
                      </p>
                      <p class="tw-text-left tw-text-gray-400 tw-whitespace-nowrap tw-mt-0.5 tw-text-xs">
                        {{ formatFileSize(item.payload.fileSize) }}
                      </p>
                    </div>
                    <div class="tw-w-7">
                      <img class="tw-w-full" src="../../assets/images/public/file-lines-regular.svg" />
                    </div>
                  </div>
                </a>
              </div>
              <div v-if="isFromMe(item.from) && !item.isRead" class="msg_chart_item_text_status">
                未读
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="msg_chart_footer">
      <!-- <div class="msg_chart_footer_voice" @click="recordHandler">
        <i class="iconfont icon-voice" />
      </div> -->
      <textarea v-if="!isRecordType" class="msg_chart_footer_input" @mouse="handleKeyUp" v-model="msg" @click="closeOther" />
      <!-- <button v-else class="msg_chart_footer_record" @touchstart="startRecord" @touchend="stopRecord">{{isRecord?'松开结束':'按住说话'}}</button> -->
      <el-popover placement="top" width="400" trigger="click">
        <div class="emojis">
          <div v-for="item in emojiName" class="emoji" :key="item" @click="chooseEmoji(item)">
            <img :src="emojiUrl + emojiMap[item]" style="width:30px;height:30px" />
          </div>
        </div>
        <i class="iconfont icon-smile msg_chart_footer_emoji" slot="reference" title="发表情" @click="closeOther"></i>
      </el-popover>
      <button v-if="msg.length > 0" class="msg_chart_footer_send" @click="sendMessage">
        发送
      </button>
      <div v-else class="msg_chart_footer_other" @click.prevent="handlerOpenOther">
        <img src="@/assets/images/public/ic_order_cancel_the_order.png" />
      </div>
    </div>
    <div v-if="openOther" class="msg_chart_other">
      <div class="msg_chart_other_item">
        <button class="msg_chart_other_item_btn" @click="handleUpload">
          <i class="el-icon-picture"></i>
          相册
        </button>
      </div>
      <div class="msg_chart_other_item">
        <button class="msg_chart_other_item_btn" @click="handleCamera">
          <i class="el-icon-camera-solid"></i>
          拍摄
        </button>
      </div>
      <div class="msg_chart_other_item">
        <button class="msg_chart_other_item_btn" @click="handleVideo">
          <i class="el-icon-video-camera-solid"></i>
          视频
        </button>
      </div>
      <!-- <div class="msg_chart_other_item">
        <button class="msg_chart_other_item_btn" @click="handleAudio">
          <i class="el-icon-microphone"></i>
          音频
        </button>
      </div> -->
    </div>
    <div v-if="isRevicew" class="msg_chart_review">
      <div class="msg_chart_review_head">
        <img src="@/assets/images/public/ic_comon_back.webp" @click="closeReview" />
      </div>
      <div v-if="currentImgSrc.length > 0" class="msg_chart_review_img">
        <img :src="currentImgSrc" />
      </div>
      <div v-else-if="currentVideoSrc.length > 0" class="msg_chart_review_img">
        <video controls>
          <source :src="currentVideoSrc" :type="currentVideoType" />
        </video>
      </div>
    </div>
    <!-- <audio ref="test" controls/> -->
    <input class="msg_chart_upload_el" ref="uploadEl" type="file" accept="image/*,video/*" multiple @change="handleImageOrVideoSelected" />
    <input class="msg_chart_upload_el" type="file" accept="image/*" capture="environment" ref="camera" @change="handleCameraCapture" />
    <input class="msg_chart_upload_el" type="file" accept="video/*" capture="user" ref="video" @change="handleVideoCapture" />
    <!-- <input class="msg_chart_upload_el" type="file" accept="audio/*" ref="microphone" @change="handleAudioChange" /> -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import SlotHeader from "@/components/SlotHeader";
import { emojiMap, emojiName, emojiUrl } from "@/utils/emojiMap";
import { decodeText } from "@/utils/decodeText";

export default {
  name: "MsgChart",
  components: {
    SlotHeader,
  },
  data() {
    return {
      msg: "",
      emojiMap: emojiMap,
      emojiName: emojiName,
      emojiUrl: emojiUrl,
      openOther: false,
      isRecordType: false,
      isRecord: false,
      oldScrollHeight: 0,
      isRevicew: false,
      currentImgSrc: "",
      currentVideoSrc: "",
      currentVideoType: "",
      stream: null,
      recorder: null,
      chunks: [],
    };
  },
  computed: {
    ...mapState("message", ["msgContent", "isTimLogin", "nextReqMessageID", "isCompleted", "signal", "usersOnlineStatus"]),
    ...mapState("orders", ["logData"]),
    onlineStatus() {
      if (this.usersOnlineStatus.json) {
        return this.usersOnlineStatus.json.QueryResult[0].Status === "Offline" ? "离线" : "在线";
      }
      return "离线";
    },
    formatFileSize() {
      return (bytes) => {
        if (bytes < 1024) {
          return bytes + " B";
        } else if (bytes < 1024 * 1024) {
          return (bytes / 1024).toFixed(2) + " KB";
        } else {
          return (bytes / (1024 * 1024)).toFixed(2) + " MB";
        }
      };
    },
  },
  watch: {
    isTimLogin: {
      handler(val) {
        if (val && this.msgContent.length <= 0) {
          this.getChartMsg();
        }
        this.setMessageRead(this.$route.params.conversationID);
        this.getUsersOnlineStatus({ toAccount: this.$route.params.userID });
      },
    },
    msgContent: {
      handler() {
        let list = this.$refs.list;
        if (list.scrollHeight === list.scrollTop + list.clientHeight)
          this.$nextTick(() => {
            list.scrollTop = list.scrollHeight;
          });
        else if (list.scrollTop === 0)
          this.$nextTick(() => {
            list.scrollTop = list.scrollHeight - this.oldScrollHeight;
          });
        this.oldScrollHeight = list.scrollHeight;
      },
      deep: true,
    },
    logData(e) {
      if (e.status == 7 || e.status == 5 || e.status == 1 || e.status == 4) {
        this.$router.push({
          path: "/orderInfo",
          query: { orderId: e.id },
        });
      } else if (e.generationMethod == 1) {
        if (e.status == 0) {
          this.$router.push({
            path: "/orderInformation",
            query: {
              orderId: e.id,
              orderNo: e.orderNo,
            },
          });
        } else if (e.status == 3) {
          this.$router.push({
            path: "/sellerConfirmation",
            query: {
              orderId: e.id,
              orderNo: e.orderNo,
            },
          });
        }
      } else if (e.generationMethod == 0) {
        if (e.status == 2 || e.status == 3) {
          this.$router.push({
            path: "/waitingForBuyersPayment",
            query: {
              orderId: e.id,
              orderNo: e.orderNo,
            },
          });
        }
      }
    },
  },
  methods: {
    ...mapActions("message", ["setMessageRead", "getMsgContent", "sendMsg", "sendImg", "sendAudio", "sendVideo", "cleanChartInfo", "getUsersOnlineStatus"]),
    ...mapActions("orders", ["getLogId"]),
    handleBack() {
      this.$router.go(-1);
    },
    getAvatar(avatar) {
      if (avatar) return avatar;
      return require("@/assets/images/public/ic_my_head.webp");
    },
    getChartMsg() {
      const data = {
        conversationID: this.$route.params.conversationID,
        count: 15,
      };
      if (this.nextReqMessageID) data.nextReqMessageID = this.nextReqMessageID;
      this.getMsgContent(data);
    },
    goToDetail(item) {
      let data = JSON.parse(item.payload.data);
      this.getLogId(data.orderId);
    },
    isFromMe(id) {
      return this.signal.id === id;
    },
    handleKeyUp(element) {
      element.target.style.height = "1px";
      element.target.style.height = `${element.target.scrollHeight + 2}px`;
    },
    sendMessage() {
      this.closeOther();
      const data = {
        text: this.msg,
        to: this.$route.params.userID,
      };
      this.sendMsg(data);
      this.msg = "";
    },
    chooseEmoji(item) {
      this.msg += item;
    },
    decodeText(item) {
      return decodeText(item);
    },
    handlerOpenOther() {
      this.openOther = true;
    },
    closeOther() {
      this.openOther = false;
    },

    handleScroll() {
      this.$refs.list.onscroll = () => {
        const list = this.$refs.list;
        if (list.scrollTop === 0 && !this.isCompleted) {
          this.getChartMsg();
        }
      };
    },
    handleUpload() {
      console.log(this.$refs);
      this.$refs.uploadEl.click();
    },
    // handleAudio() {
    //   console.log(this.$refs);
    //   this.$refs.microphone.click();
    // },
    // handleAudioChange(event) {
    //   console.log(this.$refs);
    //   const selectedFile = event.target.files[0];
    //   if (selectedFile) {
    //     console.log(selectedFile);
    //     console.log();
    //     // 执行文件上传操作，可以在这里调用你之前创建的上传组件或直接处理文件上传逻辑
    //     console.log("Selected File:", selectedFile);
    //     const data = {
    //       to: this.$route.params.userID,
    //       file: selectedFile,
    //       onProgress: (e) => {
    //         console.log(e);
    //       },
    //     };
    //     console.log(data);
    //     if (selectedFile.type.includes("audio")) {
    //       this.sendAudio(data);
    //     }
    //   }
    // },
    handleImageOrVideoSelected(event) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        // 执行文件上传操作，可以在这里调用你之前创建的上传组件或直接处理文件上传逻辑
        const data = {
          to: this.$route.params.userID,
          file: selectedFile,
          onProgress: (e) => {
            console.log(e);
          },
        };
        if (selectedFile.type.includes("video")) {
          this.sendVideo(data);
        } else {
          this.sendImg(data);
        }
      }
    },
    reviewImg(infoArray) {
      this.currentImgSrc = infoArray[0].imageUrl;
      this.isRevicew = true;
    },
    playVideo(payload) {
      this.currentVideoSrc = payload.remoteVideoUrl;
      this.currentVideoType = `video/${payload.videoFormat}`;
      this.isRevicew = true;
    },
    closeReview() {
      this.currentImgSrc = "";
      this.currentVideoSrc = "";
      this.currentVideoType = "";
      this.isRevicew = false;
    },
    show(item) {
      return item;
    },
    handleCamera() {
      this.$refs.camera.click();
    },
    handleCameraCapture() {
      const data = {
        to: this.$route.params.userID,
        file: this.$refs.camera,
        onProgress: (e) => {
          console.log(e);
        },
      };
      this.sendImg(data);
    },
    handleVideo() {
      this.$refs.video.click();
    },
    handleVideoCapture(event) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        // 执行文件上传操作，可以在这里调用你之前创建的上传组件或直接处理文件上传逻辑
        const data = {
          to: this.$route.params.userID,
          file: selectedFile,
          onProgress: (e) => {
            console.log(e);
          },
        };
        this.sendVideo(data);
      }
    },
  },
  created() {
    if (this.isTimLogin) {
      this.getChartMsg();
      this.setMessageRead(this.$route.params.conversationID);
      this.getUsersOnlineStatus({ toAccount: this.$route.params.userID });
    }
  },
  mounted() {
    this.handleScroll();
  },
  destroyed() {
    this.cleanChartInfo();
  },
};
</script>

<style lang="scss" scoped>
.msg_chart {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;

  &_status {
    font-size: 0.8rem;
    text-decoration: underline;
    margin-left: 1rem;
  }

  &_body {
    flex: auto 1 1;
    overflow-y: auto;
    background: var(--light-gray);
    padding: 0 0.5rem;
  }

  &_item {
    margin: 1.5rem 0;
    display: flex;
    justify-content: center;
    align-content: center;

    &_custom {
      max-width: 80vw;
      background: var(--font-gray);
      color: var(--gray);
      padding: 0.5rem 1rem;
      border-radius: 2rem;
      font-size: 0.9rem;
    }

    &_order {
      width: 90vw;
      background: var(--orange);
      border-radius: 10px;
      color: var(--white);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 1rem;

      &_amount {
        border-right: 1px dashed var(--white);
        padding-right: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;

        div {
          flex: auto 1 1;
          height: 2.5rem;
          line-height: 2.5rem;
        }

        &_money {
          font-size: 1.2rem;
          margin-right: 0.5rem;
        }

        &_currency {
          font-size: 0.8rem;
        }
      }

      &_info {
        flex: auto 1 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        padding-left: 1rem;

        div {
          flex: auto 1 1;
          height: 2.5rem;
          line-height: 2.5rem;
        }

        &_date {
          font-size: 0.8rem;
        }
      }

      &_icon {
        img {
          width: 1.5rem;
        }
      }
    }

    &_text {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      position: relative;

      &_avatar {
        width: 3rem;
        margin-right: 0.5rem;
      }

      &_triangle {
        margin-right: -1px;
        margin-top: 1rem;
        width: 0;
        height: 0;
        border-top: 0.5rem solid transparent;
        border-right: 0.5rem solid var(--white);
        border-bottom: 0.5rem solid transparent;
        border-left: none;
      }

      &_content {
        background: var(--white);
        font-size: 1.2rem;
        padding: 1rem 0.5rem;
        border-radius: 5px;
        text-align: left;
        vertical-align: text-bottom;

        &_text {
          white-space: pre-line;
        }

        &_emoji {
          width: 1.5rem;
        }

        &_img {
          max-width: 100%;
        }
      }

      &.fromMe {
        flex-direction: row-reverse;
      }
    }

    &_text.fromMe {
      .msg_chart_item_text_avatar {
        margin-right: 0;
        margin-left: 0.5rem;
      }

      .msg_chart_item_text_triangle {
        margin-left: -1px;
        margin-top: 1rem;
        width: 0;
        height: 0;
        border-top: 0.5rem solid transparent;
        border-left: 0.5rem solid var(--green);
        border-bottom: 0.5rem solid transparent;
        border-right: none;
      }

      .msg_chart_item_text_content {
        background: var(--green);
      }
    }
  }

  &_footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-top: 1px solid var(--gray);
    background: var(--light-gray);
    padding: 0.5rem;

    &_voice {
      border: 1px solid var(--font-gray);
      border-radius: 50%;
      padding: 0.2rem;
      margin-right: 0.5rem;

      i {
        font-size: 1.5rem;
        color: var(--font-gray);
      }
    }

    &_input {
      flex: auto 1 1;
      resize: none;
      border: 1px solid var(--font-gray);
      border-radius: 5px;
      margin-right: 0.5rem;
      font-size: 1.2rem;
      padding: 0.5rem;
      max-height: 8rem;
      min-height: 2.6rem;
      height: 2.6rem;
      overflow: hidden;

      &:focus {
        outline: none;
      }
    }

    &_record {
      flex: auto 1 1;
      margin-right: 0.5rem;
      border: 1px solid var(--font-gray);
      border-radius: 5px;
      background: var(--white);
      height: 2.6rem;
      font-size: 1.2rem;
    }

    &_emoji {
      font-size: 2rem;
      color: var(--font-gray);
      margin-right: 0.5rem;
    }

    &_other {
      img {
        width: 2rem;
        transform: rotate(45deg);
        filter: contrast(50%);
      }
    }

    &_send {
      background: var(--orange);
      border-radius: 5px;
      padding: 0.2rem;
      border: none;
      color: var(--white);
      font-size: 1.2rem;
    }
  }

  &_other {
    flex: 30vh 0 0;
    background: var(--light-gray);
    border-top: 1px solid var(--gray);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);

    &_item {
      display: flex;
      justify-content: center;
      align-items: center;

      // height: 30vw;
      &_btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        background: none;
        border: none;

        i {
          font-size: 2rem;
          color: var(--blue);
          background: var(--white);
          padding: 1rem;
          border-radius: 5px;
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  &_upload_el {
    display: none;
  }

  &_review {
    position: fixed;
    top: 0;
    left: 0;
    background: var(--black);
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: flex-start;

    &_head {
      text-align: left;
      padding: 1em;

      img {
        width: 1.5em;
        height: auto;
        filter: contrast(0);
      }
    }

    &_img {
      flex: auto 1 1;
      display: flex;
      align-items: center;

      img,
      video {
        width: 100vw;
      }
    }
  }
}

.emojis {
  height: 160px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
}

.emoji {
  height: 40px;
  width: 40px;
  box-sizing: border-box;
}

.msg_chart_item_text_status {
  flex: 24px 0 0;
  margin-right: 6px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  div {
    font-size: 12px;
    color: var(--color-font-light);
    line-height: 14px;
  }
}

.bule {
  background-color: #3a86ef;
}
</style>
